(function () {
	'use strict';

	angular
		.module('app')
		.factory('basecamp', [
			'basecampConfig',
			'$q',
			'$timeout',
			'seedcodeCalendar',
			'calendarIO',
			'fullCalendarBridge',
			'manageSettings',
			'utilities',
			'dataStore',
			'daybackIO',
			'manageFetch',
			basecamp,
		]);

	function basecamp(
		basecampConfig,
		$q,
		$timeout,
		seedcodeCalendar,
		calendarIO,
		fullCalendarBridge,
		manageSettings,
		utilities,
		dataStore,
		daybackIO,
		manageFetch
	) {
		//Create our connection for basecamp services
		var connection = createConnection();

		var eventEditQueue = {};

		var eventCache = {};
		var peopleCache = {};
		var peoplePromises = {};
		var saveStateString = 'basecamp';

		var basecampSchedules;
		var initialFetchProjectIDs;

		var queryHasTodos = {};

		var eventsInitialized;

		var primarySource;

		bcBack.setErrorReporter(errorReporter);

		return {
			getConfig: getConfig,
			getFieldMap: getFieldMap,
			getUnusedMap: getUnusedMap,
			getAllowHTMLMap: getAllowHTMLMap,
			getHiddenFieldMap: getHiddenFieldMap,
			getReadOnlyFieldMap: getReadOnlyFieldMap,
			getAllowTextFieldMap: getAllowTextFieldMap,
			getEventEditQueue: getEventEditQueue,
			sourceButtons: sourceButtons,
			auth: auth,
			deauthorize: deauthorize,
			getSchedules: getSchedules,
			changeScheduleName: changeScheduleName,
			changeScheduleColor: changeScheduleColor,
			disableSchedule: disableSchedule,
			refresh: refresh,
			getEvents: getEvents,
			editEvent: editEvent,
			deleteEvent: deleteEvent,
			eventFocus: eventFocus,
			todoConfig: todoConfig,
			onSignOut: onSignOut,
		};

		function getConfig() {
			return basecampConfig.config();
		}

		function getFieldMap() {
			return basecampConfig.fieldMap();
		}

		function getUnusedMap() {
			return basecampConfig.unusedMap();
		}

		function getAllowHTMLMap() {
			return basecampConfig.allowHTMLMap();
		}

		function getHiddenFieldMap() {
			return basecampConfig.hiddenFieldMap();
		}

		function getReadOnlyFieldMap() {
			return basecampConfig.readOnlyFieldMap();
		}

		function getAllowTextFieldMap() {
			return false;
		}

		function getEventEditQueue() {
			return eventEditQueue;
		}

		function createConnection() {
			var connection = basecampConfig.apiConnection();
			return connection;
		}

		function errorReporter(
			status,
			additionalMessage,
			preventDeauth,
			callback
		) {
			var config = seedcodeCalendar.get('config');

			var message = 'There was a problem communicating with Basecamp.';

			if (config.passthroughEditErrors) {
				return;
			}

			if (additionalMessage) {
				message += ' ' + additionalMessage;
			}

			if (!preventDeauth && (status === 401 || status === 403)) {
				message += ' - ' + 'please re-authorize DayBack.';
				if (primarySourceTemplate)
					deauthorize(callback, false, primarySourceTemplate);
			}
			if (!config.suppressEditEventMessages) {
				utilities.showMessage(message, 0, 8000, 'error');
			}
		}

		function deauthorize(callback, saveToken, sourceTemplate) {
			var user = daybackIO.getUser();
			var source = primarySource;
			var schedules = seedcodeCalendar.get('schedules');
			var calendarElement = seedcodeCalendar.get('element');
			var eventSources = seedcodeCalendar.get('eventSources');

			var retainedSchedules = [];
			var removedSchedules = [];

			// dataStore.clearState(saveStateString);
			bcBack.deauthorize(user.id, source.id, saveToken, function () {
				// Remove auth status
				sourceTemplate.status.authed = false;

				// Only try to update calendar if it exists - Not used when initiate from settings
				if (seedcodeCalendar.get('view')) {
					// Remove Schedules and events
					for (var i = 0; i < schedules.length; i++) {
						if (schedules[i].sourceTypeID !== sourceTemplate.id) {
							retainedSchedules.push(schedules[i]);
						} else {
							removedSchedules.push(schedules[i]);
						}
					}

					seedcodeCalendar.init('schedules', retainedSchedules);

					for (var i = 0; i < removedSchedules.length; i++) {
						// Remove event sources from fullcalendar
						calendarElement.fullCalendar(
							'removeEventSource',
							removedSchedules[i].source,
							removedSchedules[i].id
						);

						//Remove event source from eventSourcesObject
						if (
							eventSources.indexOf(removedSchedules[i].source) >
							-1
						) {
							eventSources.splice(
								eventSources.indexOf(
									removedSchedules[i].source
								),
								1
							);
						}
					}

					// Remove orphanes
					var events = calendarElement.fullCalendar('clientEvents');
					for (var i = 0; i < events.length; i++) {
						if (
							events[i].schedule.sourceTypeID ===
							sourceTemplate.id
						) {
							calendarElement.fullCalendar(
								'removeEvents',
								events[i]
							);
						}
					}
				}

				if (callback) {
					callback();
				}
			});
		}

		function addPeopleToResources(loading) {
			var modalTitle = 'Add People To Resources';
			var modalContent =
				'Would you like to add all of the people in your Basecamp organization to the list of resources?';
			var cancelButtonText = 'No';
			var confirmButtonText = 'Add People';
			var cancelFunction = null;
			var confirmFunction = function () {
				loading.isLoading = true;
				bcBack.getPeople(null, function (result) {
					//Remove dupes and filter out anything that doesn't have the personable_type of User
					result = utilities.removeObjectArrayDupes(
						result,
						'name',
						function (item) {
							return item.personable_type === 'User';
						}
					);

					//Define resources
					var resources = {};
					var folderID = '0-default-basecamp-folder-1';
					var nameParts;
					var shortName;

					//Create folder
					resources[folderID] = {
						id: folderID,
						folderID: folderID,
						name: 'Basecamp People',
						isFolder: true,
					};

					for (var i = 0; i < result.length; i++) {
						//Create short name
						shortName = '';
						nameParts = result[i].name.split(' ');

						for (var ii = 0; ii < nameParts.length; ii++) {
							shortName += nameParts[ii].charAt(0).toUpperCase();
						}

						//Build resource object
						resources[result[i].id] = {
							id: result[i].id,
							name: result[i].name,
							shortName: shortName,
							folderID: folderID,
						};
					}

					manageSettings.updateResource(resources, null, function () {
						$timeout(function () {
							loading.isLoading = false;
							loading.complete = true;
						}, 0);

						//remove loading messaging
						$timeout(function () {
							loading.complete = false;
						}, 3500);
					});
				});
			};
			utilities.showModal(
				modalTitle,
				modalContent,
				cancelButtonText,
				cancelFunction,
				confirmButtonText,
				confirmFunction
			);
		}

		function sourceButtons() {
			var buttons = [
				{
					name: 'Add People To Resources',
					run: addPeopleToResources,
					loading: {
						isLoading: false,
						complete: false,
						completeText: 'People added successfully',
					},
				},
			];

			return buttons;
		}

		function auth(callback, source, statusOnly, forceConsent) {
			// var basecampAccess = dataStore.getState('basecamp');
			var user = daybackIO.getUser();
			if (!source) {
				source = primarySource;
			}
			// basecampAccess = basecampAccess ? JSON.parse(crypt.hashToString(basecampAccess)) : {};

			// var tokenExpires = basecampAccess.tokenExpires ? moment.utc(basecampAccess.tokenExpires).valueOf() : null;
			// refreshToken = refreshToken ? crypt.hashToString(refreshToken) : null;

			//Run our authorization
			bcBack.auth(
				user.id,
				source.id,
				statusOnly,
				forceConsent,
				connection.authIsRedirect,
				connection.authRedirectFunction,
				callback
			);

			// bcBack.initialize(initializeComplete, statusOnly, connection.authIsRedirect, connection.authRedirectFunction, basecampAccess.accessToken, basecampAccess.refreshToken, tokenExpires, httpsCheck, utilities.showMessage);

			function httpsCheck(callback) {
				utilities.requiresHTTPS('Basecamp HTTPS Required', callback);
			}

			// function initializeComplete(result) {
			// 	var saveState = {};
			// 	if (result) {
			// 		saveState.accessToken = result.accessToken;
			// 		saveState.refreshToken = result.refreshToken;
			// 		saveState.tokenExpires = result.expiresOn;

			// 		dataStore.saveState(saveStateString, crypt.stringToHash(JSON.stringify(saveState)));

			// 		//Add people to cache
			// 		//if (result.people) {
			// 		// peopleCache = result.people;
			// 		//}
			// 	}
			// 	if (callback) {
			// 		callback(result);
			// 	}
			// }
		}

		function getSchedules(callback, sourceTemplate) {
			var config = seedcodeCalendar.get('config');
			var sources = seedcodeCalendar.get('sources');
			var userProfile = bcBack.getUserProfile();
			var source;

			//Locate our dayback source data for google calendar
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTemplate.id) {
					source = sources[i];
					if (userProfile) {
						source.name = userProfile.name;
						source.email = userProfile.email_address;
					}
					break;
				}
			}

			primarySource = source;

			//Run our authorization
			auth(authCallBack, null, true, null);

			function authCallBack(auth) {
				if (auth) {
					bcBack.getSchedules(getSchedulesCallback);
				} else {
					callback(false, sourceTemplate);
				}

				sourceTemplate.status.authed = auth ? true : false;
				//Clear saved state / token if we are not authed
				// if (!auth) {
				// 	dataStore.clearState(saveStateString);
				// }
			}

			function getSchedulesCallback(scheduleList) {
				if (!source) {
					callback([], sourceTemplate);
					return;
				}

				applySchedules(scheduleList, source.id);
			}

			function applySchedules(schedules, sourceID) {
				var scheduleID;
				//Clean schedule data
				for (var i = 0; i < schedules.length; i++) {
					//Merge source data that is missing from the returned calendar / schedule
					for (var property in source) {
						if (schedules[i][property] === undefined) {
							schedules[i][property] = source[property];
						}
					}

					schedules[i].sourceID = sourceID;

					//Add dayback source data to schedules because this is not a 1:1 source type
					schedules[i].customFields = source.customFields
						? JSON.parse(JSON.stringify(source.customFields))
						: null;
					schedules[i].customActions = source.customActions
						? JSON.parse(JSON.stringify(source.customActions))
						: null;
					schedules[i].eventActions = source.eventActions
						? JSON.parse(JSON.stringify(source.eventActions))
						: null;

					scheduleID = utilities.stringToID(schedules[i].id);

					if (source[scheduleID]) {
						//Append schedule specific items to already specified source items
						for (var property in source[scheduleID]) {
							if (
								schedules[i][property] &&
								(property === 'eventActions' ||
									property === 'customActions' ||
									property === 'customFields')
							) {
								for (var calendarProperty in source[scheduleID][
									property
								]) {
									schedules[i][property][calendarProperty] =
										source[scheduleID][property][
											calendarProperty
										];
								}
							} else {
								schedules[i][property] =
									sourceTemplate.settings[property] &&
									!sourceTemplate.settings[property]
										.scheduleOnly &&
									source[property] !== 'disable-global'
										? source[property]
										: source[scheduleID][property];
							}
						}
					}

					//Set source specific custom field properties
					if (schedules[i].customFields) {
						for (var property in schedules[i].customFields) {
							schedules[i].customFields[property].mutateInput =
								customFieldMutateInput;
						}
					}
					calendarIO.cleanSchedule(schedules[i], sourceTemplate);
				}
				basecampSchedules = schedules;
				callback(schedules, sourceTemplate);
			}
		}

		function changeScheduleName(name, callback, schedule) {
			var editObj = {
				name: name,
			};
			bcBack.updateProject(schedule.projectID, editObj, callback);
		}

		function changeScheduleColor(color, callback, schedule) {
			manageSettings.setBackgroundColor(color, schedule, callback);
			return color;
		}

		function disableSchedule(scheduleID) {
			manageFetch.clear(scheduleID);
		}

		// function changeScheduleColor(color, callback, schedule) {
		//   var message = "Changing calendar colors on Basecamp schedules is not currently supported. Any color changes made will not be saved. Any other changes will be saved.";
		//   utilities.showModal('Changing calendar color not supported', message, 'OK', null, null, null);
		// }

		function refresh(schedule, callback) {
			eventsInitialized = null;
			eventCache = {};
			bcBack.clearEventCache();

			if (callback) {
				callback();
			}
		}

		function getEvents(
			start,
			end,
			timezone,
			callbackFunc,
			schedule,
			options,
			eventID,
			fetchID,
			requestOverride
		) {
			var eventsDeferred = $q.defer();
			var calendarElement = seedcodeCalendar.get('element');
			var config = seedcodeCalendar.get('config');
			var queryDate = calendarElement.fullCalendar('getDate');
			var projectIDString;

			var callback = manageFetch.create(
				schedule.id,
				'event',
				callbackFunc
			).confirm;

			//Mark that we have previously fetched events
			if (!eventsInitialized) {
				eventsInitialized = true;
				initialFetchProjectIDs = getInitialSelectedProjectIDs();
			}

			//If we have a list of projectIDs from inital load
			if (initialFetchProjectIDs && initialFetchProjectIDs.length) {
				projectIDString = initialFetchProjectIDs.join(',');
			}

			//Remove the projectID from initial selected so we know we fetched for this project
			var index = initialFetchProjectIDs.indexOf(schedule.projectID);
			if (index > -1) {
				initialFetchProjectIDs.splice(index, 1);
			}

			//var queryStart = moment.tz(moment(start).format('YYYY-MM-DD'), schedule.timeZone).format();
			//var queryEnd = moment.tz(moment(end).format('YYYY-MM-DD'), schedule.timeZone).format();

			var queryStart = start.format();
			var queryEnd = end.format();

			//Check if the allow todo option has changed
			if (queryHasTodos[schedule.id] !== schedule.allowTodo) {
				queryHasTodos[schedule.id] = schedule.allowTodo;
				eventCache[schedule.id] = null;
			}
			if (!eventCache[schedule.id]) {
				// Run before events fetched action
				const actionCallbacks = {
					confirm: function () {
						bcBack.getEvents(
							schedule.projectID,
							schedule.id,
							schedule.allowTodo,
							queryStart,
							queryEnd,
							projectIDString,
							eventsDeferred,
							processEvents
						);
					},
					cancel: function () {
						processEvents([]);
					},
				};

				const actionResult = calendarIO.runEventActions(
					'beforeEventsFetched',
					{schedule: schedule},
					true,
					actionCallbacks,
					null
				);

				if (!actionResult) {
					return;
				}

				bcBack.getEvents(
					schedule.projectID,
					schedule.id,
					schedule.allowTodo,
					queryStart,
					queryEnd,
					projectIDString,
					eventsDeferred,
					processEvents
				);
			} else {
				//Wrap in a settimeout so we make sure this is async as we expect these calls to be
				window.setTimeout(function () {
					processEvents(eventCache[schedule.id]);
				}, 0);
			}

			function processEvents(result) {
				//Update project lists (for todos)
				schedule.lists = bcBack.getStoredLists(schedule.projectID);
				eventCache[schedule.id] = JSON.parse(JSON.stringify(result));
				if (!result) {
					// schedule.error = result.error;
					callback();
					return;
				}
				callback(
					mutateCalendarEvents(result, schedule, schedule.fieldMap)
				);
			}
		}

		function customFieldMutateInput(item, fieldDefinition) {
			var result;
			if (fieldDefinition.formatas === 'select') {
				if (!item) {
					result = [];
				} else if (Array.isArray(item)) {
					result = item;
				} else {
					try {
						result = JSON.parse(item);
						if (!Array.isArray(result)) {
							result = [item];
						}
					} catch (error) {
						result = [item];
					}
				}
				calendarIO.replaceWithNoFilterValue(result);
			} else {
				result = item;
			}
			return result;
		}

		function customFieldMutateOutput(item, customField, schedule) {
			if (customField && customField.formatas === 'date') {
				return item ? moment(item).format(schedule.fileDateFormat) : '';
			} else if (customField && customField.formatas === 'timestamp') {
				return item
					? moment(item).format(schedule.fileTimestampFormat)
					: '';
			} else if (Array.isArray(item)) {
				calendarIO.removeNoFilterValue(item);
				return JSON.stringify(item);
			} else {
				return item;
			}
		}

		function mutateCalendarEvents(events, schedule, fieldMap) {
			const hookResult = calendarIO.mutateHook();
			if (!hookResult) {
				return [];
			}
			var customFields = schedule.customFields;
			var event;
			var propertyValue;
			var output = [];
			// for (var i = 0; i < events.length; i++) {
			//   output.push(mutate(events[i]));
			// }

			for (var i = 0; i < events.length; i++) {
				event = {};
				for (var property in fieldMap) {
					propertyValue = events[i][fieldMap[property]];
					event[property] = propertyValue;
					// event[property] = propertyValue === undefined ? "" : String(propertyValue);
				}
				output.push(mutate(event));
			}

			return output;
			function mutate(event) {
				//We can use this to create a recurring event in FullCalendar
				// if (event.recurringEventID) {
				//   event.id = event.recurringEventID;
				// }

				// event.titleEdit = event.summary;
				// event.title = event.summary;

				event.eventSource = schedule.id;
				event.schedule = schedule;
				event.color = schedule.backgroundColor;
				event.textColor = schedule.foregroundColor;

				//field specific transformations
				event.todo = event.type === 'Todo';

				// event.id = event.id.toString();

				var daybackFieldBlockMatch;
				if (
					event.description?.includes(
						'<pre data-controller="syntax-highlight">'
					)
				) {
					daybackFieldBlockMatch = new RegExp(
						'<pre data-controller="syntax-highlight"><strong>Do Not Modify</strong> DayBack Field Data Start -- (.*?) -- <strong>Do Not Modify</strong> DayBack Field Data End</pre>'
					);
				} else {
					daybackFieldBlockMatch = new RegExp(
						'<pre><strong>Do Not Modify</strong> DayBack Field Data Start -- (.*?) -- <strong>Do Not Modify</strong> DayBack Field Data End</pre>'
					);
				}
				var daybackFieldListSeparator = '!~end~!';
				var daybackFieldValueSeparator = '!~value~!';

				var daybackFieldResult = event.description.match(
					daybackFieldBlockMatch
				);

				if (daybackFieldResult) {
					var daybackFieldReplace = daybackFieldResult[0];
					var daybackFieldList = daybackFieldResult[1].split(
						daybackFieldListSeparator
					);
					for (var i = 0; i < daybackFieldList.length; i++) {
						var fieldListItem = daybackFieldList[i].split(
							daybackFieldValueSeparator
						);
						var fieldName = fieldListItem[0]
							? fieldListItem[0]
							: null;
						var fieldValue = fieldListItem[1]
							? fieldListItem[1]
							: null;
						if (fieldName) {
							event[fieldName] = fieldValue;
						}
					}
					//Remove our field stuffing block from the description field
					if (
						event.description.slice(
							daybackFieldReplace.length * -1 - 8,
							daybackFieldReplace.length * -1
						) === '<br><br>'
					) {
						daybackFieldReplace = '<br><br>' + daybackFieldReplace;
					} else if (
						event.description.slice(
							daybackFieldReplace.length * -1 - 4,
							daybackFieldReplace.length * -1
						) === '<br>'
					) {
						daybackFieldReplace = '<br>' + daybackFieldReplace;
					}
					event.description = event.description.replace(
						daybackFieldReplace,
						''
					);
				}

				//convert empty resource to array
				if (!event.resource || !Array.isArray(event.resource)) {
					event.resource = [];
				}

				for (var i = 0; i < event.resource.length; i++) {
					event.resource[i] = event.resource[i].name;
				}

				//convert status to array
				try {
					event.status = JSON.parse(event.status);
				} catch (error) {
					event.status = [];
				}

				//convert tags to array
				try {
					event.tags = JSON.parse(event.tags);
				} catch (error) {
					event.tags = [];
				}

				try {
					event.contactName = JSON.parse(event.contactName);
				} catch (error) {
					event.contactName = [];
				}

				try {
					event.contactID = JSON.parse(event.contactID);
				} catch (error) {
					event.contactID = [];
				}

				try {
					event.projectName = JSON.parse(event.projectName);
				} catch (error) {
					event.projectName = [];
				}

				try {
					event.projectID = JSON.parse(event.projectID);
				} catch (error) {
					event.projectID = [];
				}

				//Adjust custom field data types from strings to appropriate format
				if (customFields) {
					calendarIO.assignCustomFields(event, customFields);
				}

				//return mulated event
				return calendarIO.cleanEvent(event);
			}
		}

		function editEvent(
			event,
			revertObject,
			revertFunc,
			changes,
			editID,
			callback,
			schedule
		) {
			var config = seedcodeCalendar.get('config');
			var fieldMap = schedule.fieldMap;

			var customFields = schedule.customFields;
			var translatedProperty;
			var editObj = {};
			var additionalEditProperties = {};
			var fieldForUnsupported = 'description';
			var compareProperty;

			//Used for determining start / end date format below
			var isAllDay = changes.hasOwnProperty('allDay')
				? changes.allDay
				: event.allDay;

			//We need to always send start and end date / times with edit requests
			if (!changes.start) {
				changes.start = moment(event.start);
			}
			if (!changes.end) {
				changes.end = moment(event.end);
			}
			for (var property in fieldMap) {
				//Don't send unused / unmapped items to edit object
				if (!fieldMap[property] || schedule.unusedMap?.[property]) {
					continue;
				}

				//Basecamp requires that we write all fields in the API request
				//We're already verifying the field is in the fieldmap above
				if (
					fieldMap[property] &&
					property !== 'eventID' &&
					property !== 'title'
				) {
					translatedProperty = fieldMap[property];

					//If property exists in changes object, use that. Otherwise, use the event property
					compareProperty = changes.hasOwnProperty(property)
						? changes[property]
						: event[property];

					//format start and end as needed
					if (property === 'start') {
						editObj[translatedProperty] = moment(
							isAllDay
								? moment(compareProperty).format(
										schedule.fileDateFormat
									)
								: compareProperty
						);
					} else if (property === 'end') {
						editObj[translatedProperty] = moment(
							isAllDay
								? moment(compareProperty)
										.subtract(1, 'days')
										.format(schedule.fileDateFormat)
								: compareProperty
						);
					} else if (
						property === 'description' &&
						typeof compareProperty === 'undefined'
					) {
						editObj[translatedProperty] = '';
					} else if (
						property === 'resource' ||
						property === 'status'
					) {
						editObj[translatedProperty] = compareProperty.slice(); //Using slice here to clone the array
						//Remove "none" label from resources so we don't write it back to the source
						for (
							var i = 0;
							i < editObj[translatedProperty].length;
							i++
						) {
							if (
								editObj[translatedProperty][i] ===
								config.noFilterLabel
							) {
								editObj[translatedProperty][i] = '';
							}
						}
					} else if (property === 'tags') {
						editObj[translatedProperty] =
							calendarIO.packageTagsOutput(compareProperty);
					} else if (Array.isArray(compareProperty)) {
						editObj[translatedProperty] = compareProperty.slice(); //Use slice here to clone the array rather than using a reference
					} else if (customFields && customFields[property]) {
						editObj[property] =
							calendarIO.customFieldOutputTransform(
								property,
								compareProperty,
								customFields,
								schedule,
								customFieldMutateOutput
							);
						//Adjust custom field data types to strings
						editObj[property] = editObj[property].toString();
					}
					// else if (property === 'description') {
					//   editObj[translatedProperty] = '<div class="attachment--hidden"><div class="attachment__hideable">Hey man how are you?</div></div>';
					// }
					else {
						editObj[translatedProperty] = event[property];
					}
				}
			}

			peoplePromises[schedule.projectID].then(processPeople);

			function processPeople() {
				//Apply any resource changes to people
				if (
					event.isDefaultResource &&
					editObj[fieldMap['resource']] &&
					editObj[fieldMap['resource']].length === 1 &&
					editObj[fieldMap['resource']][0] ===
						schedule.defaultResource.name
				) {
					editObj[fieldMap['resource']] = [''];
				}
				var participantField = event.todo
					? 'assignee_ids'
					: 'participant_ids';
				editObj[participantField] = [];

				// Only add people if we have a resource
				if (editObj[fieldMap['resource']]) {
					for (
						var i = 0;
						i < editObj[fieldMap['resource']].length;
						i++
					) {
						editObj[participantField][i] = getPersonID(
							schedule.projectID,
							editObj[fieldMap['resource']][i]
						);
						if (
							!editObj[participantField][i] &&
							editObj[fieldMap['resource']][i]
						) {
							resultCallback({
								errorCode:
									editObj[fieldMap['resource']][i] +
									' cannot be added to this event',
							});
							return;
						} else if (
							editObj[fieldMap['resource']].length > 1 &&
							!editObj[fieldMap['resource']][i]
						) {
							resultCallback({
								errorCode:
									'Basecamp does not allow an empty resource to be assigned with another resource.',
							});
							return;
						}
					}
				}
				delete editObj[fieldMap['resource']];

				//Add non supported fields to the description field (field stuffing)
				if (editObj.status) {
					if (editObj.status.length && editObj.status[0]) {
						editObj[fieldMap[fieldForUnsupported]] =
							editObj[fieldMap[fieldForUnsupported]] +
							'<br><br>' +
							'<pre>' +
							'<strong>Do Not Modify</strong> DayBack Field Data Start -- ' +
							'status!~value~!' +
							JSON.stringify(editObj.status) +
							'!~end~!' +
							' -- <strong>Do Not Modify</strong> DayBack Field Data End' +
							'</pre>';
					}

					//Delete fields not compatible with basecamp
					delete editObj.status;
				}

				//Edit the event
				applyEditEvent();
			}

			//Apply our event result (write result data)
			function applyEditEvent() {
				//if we have an id we're updating if we don't we are creating.
				bcBack.updateEvent(
					schedule.projectID,
					event.eventID,
					schedule.id,
					editObj,
					event,
					resultCallback
				);
			}
			function resultCallback(result) {
				var element = seedcodeCalendar.get('element');
				var eventResult;
				var aResult = [result];
				var message;

				//If we are dragging this event currently we need to wait and see if the event has changed or not
				if (eventEditQueue.dragging) {
					window.setTimeout(function () {
						resultCallback(result);
					}, 250);
					return;
				}

				//We are editing the same event before the last save so exit the save
				if (editID && eventEditQueue[event.eventID] > editID) {
					return;
				} else {
					delete eventEditQueue[event.eventID];
				}

				//Check for errors and if we detect one revert the record
				if (result && result.errorCode && result.errorCode !== 201) {
					if (config.passthroughEditErrors) {
						if (callback) {
							callback(null);
						}
					} else if (config.suppressEditEventMessages) {
						dragError();
					} else if (revertFunc) {
						message =
							'There was an error saving the event and your changes will be reverted: ' +
							result.errorCode;
						utilities.showModal(
							'Operation Failed',
							message,
							null,
							null,
							'Revert Changes',
							dragError
						);
					} else if (revertObject) {
						//applyEventChanges(event, revertObject); //Would revert the event but we don't want that in this case as we don't want to lose changes
						message =
							'There was an error and your event could not be saved: ' +
							result.errorCode;
						utilities.showModal(
							'Operation Failed',
							message,
							'Return To Event',
							cancelError,
							'Revert Changes',
							confirmError
						);
					}
					return;
				}

				//Update eventCache
				updateEventCache(aResult[0], schedule);

				//No errors found continue with updating the event with the returned data
				eventResult = mutateCalendarEvents(
					aResult,
					schedule,
					schedule.fieldMap
				)[0];

				eventResult.allDay = event.allDay;
				eventResult._allDay = eventResult.allDay;

				// Normalize dates
				seedcodeCalendar
					.get('element')
					.fullCalendar('buildEventDates', eventResult, eventResult);

				var modified = applyEventChanges(event, eventResult);
				if (modified) {
					fullCalendarBridge.update(element, event);
				}

				if (callback) {
					callback(event);
				}

				function dragError() {
					if (revertFunc) {
						revertFunc(null, event, result.errorCode);
					}
				}
				function confirmError() {
					if (event.eventID) {
						applyEventChanges(event, revertObject);
						calendarIO.assignEventColor(event);
						fullCalendarBridge.update(element, event);
						if (callback) {
							callback(false);
						}
					} else {
						//Remove event from view
						fullCalendarBridge.removeEvents(element, event);
					}
				}
				function cancelError() {
					if (!event.eventStatus) {
						event.eventStatus = {};
					}

					event.eventStatus.revertObject = revertObject;

					if (event.eventStatus.isCustomAction) {
						applyEventChanges(event, revertObject);
					} else {
						event.eventStatus.showPopover = true;
						event.eventStatus.unsavedChanges = true;
						fullCalendarBridge.update(element, event);
						//Reset event status for custom action state
						event.eventStatus.isCustomAction = false;
					}
				}
			}
		}

		//Apply event changes returned from backend after an edit. Also returns wether the event was modified on the server
		function applyEventChanges(event, changes) {
			var modified;
			for (var property in changes) {
				//Check string, numbers, and booleans
				if (
					(typeof changes[property] === 'undefined' ||
						typeof changes[property] === 'string' ||
						typeof changes[property] === 'number' ||
						typeof changes[property] === 'boolean' ||
						(typeof changes[property] === 'object' &&
							changes[property] === null)) &&
					property !== 'dateStart' &&
					property !== 'dateEnd' &&
					property !== 'timeStart' &&
					property !== 'timeEnd' &&
					property !== 'start' &&
					property !== 'end'
				) {
					if (
						(event[property] === undefined ||
							event[property] === null ||
							event[property] === '') &&
						(changes[property] === undefined ||
							changes[property] === null ||
							changes[property] === '')
					) {
						// we have a null value so let's just set back to event to normalize but don't mark anything as modified
						event[property] = changes[property];
					} else if (event[property] !== changes[property]) {
						if (property !== 'eventID') {
							modified = true;
						}
						event[property] = changes[property];
					}
				}

				//Check arrays
				else if (Array.isArray(changes[property])) {
					if (
						!utilities.arraysEqual(
							event[property],
							changes[property]
						)
					) {
						modified = true;
						event[property] = changes[property];
					}
				}
				//Check moment objects
				else if (
					moment.isMoment(changes[property]) ||
					property === 'start' ||
					property === 'end'
				) {
					if (
						!moment(changes[property]).isSame(
							moment(event[property])
						)
					) {
						modified = true;
						event[property] = changes[property];
					}
				}
			}
			return modified;
		}

		//Delete Event
		function deleteEvent(event, callback, schedule) {
			bcBack.deleteEvent(
				schedule.projectID,
				event.eventID,
				null,
				processDelete
			);

			function processDelete(result) {
				var message;
				if (!result || !result.errorCode || result.errorCode !== 204) {
					message =
						'There was an error deleting the event: Error ' +
						result.errorCode;
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Return To Event',
						confirmError
					);
					return;
				}

				//Update eventCache
				removeEventFromCache(event.eventID, schedule);

				calendarIO.confirmDelete(event, 0, callback);
			}
			function confirmError() {
				return;
			}
		}

		function getPersonID(projectID, name) {
			var people = peopleCache[projectID];

			for (var i = 0; i < people.length; i++) {
				if (name === people[i].name) {
					return people[i].id;
				}
			}
		}

		function todoConfig() {
			return {
				enabled: true,
				schedulesEnabled: 'all',
				createOnly: false,
				getTodoLists: getTodoLists,
				getTodoListsURL: getTodoListsURL,
			};
		}

		function updateEventCache(event, schedule) {
			//Clone the event object so we don't keep mutations
			event = JSON.parse(JSON.stringify(event));
			for (var i = 0; i < eventCache[schedule.id].length; i++) {
				if (eventCache[schedule.id][i].id === event.id) {
					eventCache[schedule.id][i] = event;
					return;
				}
			}
			//if there was no match then it is a new event so add to our array
			eventCache[schedule.id].push(event);
		}

		function removeEventFromCache(eventID, schedule) {
			for (var i = 0; i < eventCache[schedule.id].length; i++) {
				if (eventCache[schedule.id][i].id === eventID) {
					eventCache[schedule.id].splice(i, 1);
					return;
				}
			}
		}

		function getTodoLists(schedule, callback) {
			bcBack.getTodoLists(schedule.projectID, processTodoLists);
			function processTodoLists(todoLists) {
				schedule.lists = todoLists;
				if (callback) {
					callback(todoLists);
				}
			}
		}

		function getTodoListsURL(schedule) {
			var todosetURL = schedule.todosetURL
				? schedule.todosetURL
				: schedule.projectURL;
			return todosetURL;
		}

		function eventFocus(schedule, event) {
			var peopleDeferred;
			//If we have already called this and people exist in the cache we can exit
			if (peopleCache[schedule.projectID]) {
				return;
			}
			//Track a promise based on projectID so we can later check if this process has completed for this project
			peopleDeferred = $q.defer();
			peoplePromises[schedule.projectID] = peopleDeferred.promise;

			// Add people to cache stored by projectID
			bcBack.getPeople(schedule.projectID, function (result) {
				peopleCache[schedule.projectID] = result.people;
				peopleDeferred.resolve(result);
			});
		}

		function getInitialSelectedProjectIDs() {
			var schedules = basecampSchedules;
			var schedule;
			var selectedProjectIDs = [];
			for (var i = 0; i < schedules.length; i++) {
				schedule = schedules[i];
				if (schedule.status && schedule.status.selected) {
					selectedProjectIDs.push(schedule.projectID);
				}
			}
			return selectedProjectIDs;
		}

		function onSignOut() {
			bcBack.clearTokenMemory();
		}
	}
})();
